import {
  type FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useRouter } from "next/router";

type tUrl = string;

type tValue = ReturnType<typeof useHistoryManager>;

const INIT_VALUE: tValue = { history: [], canGoBack: false };

const historyManagerContext =
  createContext<ReturnType<typeof useHistoryManager>>(INIT_VALUE);

export const HistoryManagerProvider: FC<{
  value: ReturnType<typeof useHistoryManager>;
}> = ({ value, children }) => {
  return (
    <historyManagerContext.Provider value={value}>
      {children}
    </historyManagerContext.Provider>
  );
};

export const useHistory = () => useContext(historyManagerContext);

// url에서 query 제거
const getPath = (url: string) => url.split("?")[0];

export const useHistoryManager = () => {
  const router = useRouter();
  const [isPopState, setIsPopState] = useState(false);
  const [history, setHistory] = useState<tUrl[]>([router.asPath]);

  const canGoBack = useMemo(() => history.length > 1, [history]);

  // 라우터가 변경되는 모든 상황에서 실행됨
  useEffect(() => {
    const onRouteChange = (url: tUrl) => {
      if (isPopState) return setIsPopState(false);
      setHistory(prevState => {
        const currentUrlPath = getPath(url);
        const lastUrlPath = getPath(prevState[prevState.length - 1]);
        return currentUrlPath !== lastUrlPath ? [...prevState, url] : prevState;
      });
    };

    /** @url https://nextjs.org/docs/api-reference/next/router#routerevents */
    router.events.on("routeChangeComplete", onRouteChange);
    return () => router.events.off("routeChangeComplete", onRouteChange);
  }, [isPopState, router]);

  // 뒤로가기 시점에만 실행됨
  useEffect(() => {
    /** @url https://nextjs.org/docs/api-reference/next/router#routerbeforepopstate */
    router.beforePopState(() => {
      setHistory(prevState => prevState.slice(0, prevState.length - 1));
      setIsPopState(true);
      return true;
    });
  }, [router]);

  return { history, canGoBack };
};
