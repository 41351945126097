import type { AnalyzeType } from "./analyze";

type tCallAppInterfaces = {
  [key in `send${AnalyzeType}Event`]: {
    name: string;
    params?: Record<string, any>;
  };
} & {
  openNativeModal: unknown;
  showNativeToast: string;
  getAppInfo: string;
  showSelectCouponDialog: {
    svcTypeId?: number;
    oid: string;
    funcName?: string;
    couponId?: string;
  };
  testing: string;
  /**
   * @description 유저앱 모든 웹뷰를 내리고 이용내역 바텀탭 화면으로 이동시킴
   */
  notifyTechshopEvent: {
    type: "reserved";
  };
  /**
   * @description 주소 정보(우편번호, 주소)를 App에 전송
   * postzip : 우편번호
   * address : 유저가 선택한 주소
   * roadAddr : 도로명 주소
   * jibunAddr : 지번 주소
   */
  sendAddressInfoEvent: {
    postzip: string;
    address: string;
    roadAddr: string;
    jibunAddr: string;
  };
};

type tCallAppInterfaceActions = keyof tCallAppInterfaces;

export type tCallAppInterfaceParams<T extends tCallAppInterfaceActions> =
  tCallAppInterfaces[T];

// App에서 생성한 action 이름의 함수를 호출하는 인터페이스
export const callAppInterface = <T extends tCallAppInterfaceActions>(
  action: T,
  data: tCallAppInterfaceParams<T>,
) => {
  const jsonStr = typeof data === "object" ? JSON.stringify(data) : data;
  try {
    if (window.webkit?.messageHandlers) {
      // iOS
      window.webkit.messageHandlers[action].postMessage?.(jsonStr);
    } else if (window.CardocAndroid) {
      // Android
      if (window.CardocAndroid[action]) {
        window.CardocAndroid[action](jsonStr);
      }
    }
  } catch (e) {
    console.log(`App 인터페이스 함수 호출 실패 : ${e}`);
  }
};
