import { useEffect } from "react";

import { callAppInterface } from "~/utils/callAppInterface";

const useInitAppInterface = () => {
  useEffect(() => {
    window.setAppInfo = info => {
      window.app = JSON.parse(info);
    };
    callAppInterface("getAppInfo", "setAppInfo");
  }, []);
};

export default useInitAppInterface;
