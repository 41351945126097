import type { FC } from "react";

import Image from "next/image";

import styled from "@emotion/styled";

import InfoIcon from "public/assets/icons/ic-circle-info.png";

interface Props {
  resetError: () => void;
}

const ErrorBoundary: FC<Props> = ({ resetError }) => {
  return (
    <Section>
      <Image
        className={"mb-10"}
        width={50}
        height={50}
        src={InfoIcon}
        alt={"info-icon"}
      />
      <H2>
        문제가 발생했습니다.
        <br />
        잠시후 다시 시도해주세요.
      </H2>
      <Button onClick={resetError}>재시도</Button>
    </Section>
  );
};

export default ErrorBoundary;

const Section = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3d3d3d;
`;

const H2 = styled.h2`
    font-size: 22px;
    margin-top: 32px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 70px;
`;

const Button = styled.button`
    padding: 10px 14px;
    font-size: 18px;
    border-radius: 6px;
    cursor: pointer;
`;
